<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
    :description="description"
  >
    <multiselect
      :disabled="isDisabled"
      :id="id"
      v-model="inputValue"
      :label="optionLabel"
      :track-by="trackBy"
      :options="options"
      :searchable="searchable"
      :internal-search="false"
      :multiple="multiple"
      :show-labels="showLabels"
      preserveSearch
      @search-change="debounceSearch"
      @select="onSelect"
      :placeholder="placeholder"
      :hide-selected="hideSelected"
    >
      <template slot="afterList">
        <div v-if="isFetching" class="text-center py-2">
          <pulse-loader :loading="true" color="#9176cc" size="15px">
          </pulse-loader>
        </div>
        <div v-observe-visibility="reachedEndOfList" v-if="hasNextPage" />
      </template>
      <template slot="singleLabel" slot-scope="props">
        <div class="d-flex align-items-center">
          <img
            v-if="optionImage"
            :src="props.option[optionImage]"
            alt="logo"
            class="option__image"
          />
          <div>{{ props.option[optionLabel] }}</div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="d-flex align-items-center">
          <img
            v-if="optionImage"
            :src="props.option[optionImage]"
            alt="logo"
            class="option__image"
          />
          <div>
            <div>{{ props.option[optionLabel] }}</div>
            <div v-if="descriptionLabel" class="text-muted small">
              {{ props.option[descriptionLabel] }}
            </div>
          </div>
        </div>
      </template>
      <span slot="noResult"><translate>No results</translate></span>
    </multiselect>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";
import { debounce } from "@/store/ajax/actions";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import Vue from "vue";
import { ObserveVisibility } from "vue-observe-visibility";

Vue.directive("observe-visibility", ObserveVisibility);

export default {
  components: { Multiselect, PulseLoader },
  mixins: [FormGroupWithValidationMixin],
  props: {
    store: {
      type: String,
      required: true
    },
    method: {
      type: String,
      default: "fetchMultiselect"
    },
    getter: {
      type: String,
      default: "multiselectItems"
    },
    fetchParams: {
      type: Object,
      default: () => {}
    },
    perPage: {
      type: Number,
      default: 10
    },
    optionLabel: {
      type: String,
      required: false,
      default: "label"
    },
    optionImage: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: false
    },
    descriptionLabel: {
      type: [String, Boolean],
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: "value"
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    onSelect: {
      type: Function,
      required: false,
      default: () => {}
    },
    hideSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: function() {
        return this.$gettext("Select option");
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      search: "",
      currentPage: 1,
      isFetching: false,
      debounceSearch: debounce(value => {
        this.currentPage = 1;
        this.search = value;
        this.fetch();
      }, 300)
    };
  },
  computed: {
    options() {
      return this.$store.getters[`${this.store}/${this.getter}`];
    },
    hasNextPage() {
      return (
        this.perPage * this.currentPage <
        this.$store.getters[`${this.store}/multiselectTotal`]
      );
    },
    isDisabled() {
      return this.disabled || this.isPosting;
    }
  },
  created() {
    this.search = "";
    this.fetch();
  },
  methods: {
    async fetch() {
      this.isFetching = true;
      await this.$store
        .dispatch(`${this.store}/${this.method}`, {
          ...this.fetchParams,
          per_page: this.perPage,
          page: this.currentPage,
          q: this.search
        })
        .then(data => this.$emit("fetched", { ...data, search: this.search }));
      this.isFetching = false;
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.currentPage += 1;
        this.fetch();
      }
    }
  }
};
</script>
