<template>
  <div class="row">
    <b-modal
      :cancel-title="$gettext('Cancel')"
      :title="$gettext('Create application')"
      @ok="handleCreateApplication"
      @show="fetchOfferOptions"
      id="create-application-modal"
      hide-header-close
    >
      <form @submit.prevent="handleCreateApplication">
        <FormGroupMultiselectPaginate
          :label="$gettext('Offer')"
          field="offer"
          store="offers"
          option-label="title"
          track-by="id"
          v-model="applicationSelectedOffer"
          required
        />

        <FormGroupFile
          :label="$gettext('Resume')"
          field="resume"
          v-model="applicationPayload.resume"
          required
        />
        <FormGroupFile
          :label="$gettext('Cover letter')"
          field="cover_letter"
          v-model="applicationPayload.cover_letter"
        />
      </form>
    </b-modal>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"><translate>Candidate details</translate></h4>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <label><translate>First Name</translate></label>
              <p>{{ candidate.first_name }}</p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <label><translate>Last Name</translate></label>
              <p>{{ candidate.last_name }}</p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <label><translate>Email address</translate></label>
              <p>{{ candidate.email }}</p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <label><translate>Phone</translate></label>
              <p>{{ candidate.phone_number || "-" }}</p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <label><translate>City</translate></label>
              <p>{{ candidateCity }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <Fab to="create-application-modal" action-type="modal" />
        <div class="card-header">
          <h4 class="card-title"><translate>Applications</translate></h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <b-table
                :items="candidate.applications"
                :fields="applicationFields"
              >
                <template #cell(created_at)="{item}">
                  <FormattedDate :date="item.created_at" />
                </template>
                <template #cell(updated_at)="{item}">
                  <FormattedDate :date="item.updated_at" />
                </template>
                <template #cell(offer_title)="{item}">
                  <span v-if="item.offer_is_deleted">
                    {{ item.offer_title }} [DELETED]
                  </span>
                  <router-link
                    v-else
                    :to="{
                      name: 'application-candidate',
                      params: {
                        offerId: item.offer_id,
                        applicationId: item.application_id
                      }
                    }"
                    >{{ item.offer_title }}</router-link
                  >
                </template>
                <template #cell(company_name)="{item}">
                  <span v-if="item.company_is_deleted">{{
                    `${item.company_name} [DELETED]`
                  }}</span>
                  <router-link
                    v-else
                    :to="{
                      name: 'company-details',
                      params: { companyId: item.company_id }
                    }"
                    >{{ item.company_name }}</router-link
                  >
                </template>
                <template #cell(status)="{item}">
                  <ApplicationStatus
                    :current-status="item.status"
                    @statusChange="
                      status =>
                        handleStatusChange({
                          id: item.application_id,
                          status
                        })
                    "
                  />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"><translate>Documents</translate></h4>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <DocumentViewerModal
                :url="documentViewed.url"
                :title="documentViewed.title"
                :mime-type="documentViewed.mimeType"
              />
              <b-table :items="candidate.documents" :fields="documentFields">
                <template #cell(type)="{item}">
                  {{ translateDocumentType(item.type) }}
                </template>
                <template #cell(created_at)="{item}">
                  <FormattedDate :date="item.created_at" />
                </template>
                <template #cell(updated_at)="{item}">
                  <FormattedDate :date="item.updated_at" />
                </template>
                <template #cell(actions)="{item}">
                  <b-button
                    @click="handleViewDocument(item)"
                    class="btn btn-info btn-icon btn-sm"
                    v-b-tooltip.hover
                    :title="$gettext('View')"
                    ><i class="fa fa-eye"></i
                  ></b-button>
                  <a
                    class="btn btn-success btn-icon btn-sm ml-1"
                    :href="item.cdn"
                    target="_blank"
                    v-b-tooltip.hover
                    :title="$gettext('Download')"
                    ><i class="fa fa-download"></i
                  ></a>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Fab from "@/components/Fab";
import FormGroupMultiselectPaginate from "@/components/FormGroups/FormGroupMultiselectPaginate";
import FormGroupFile from "@/components/FormGroups/FormGroupFile";
import ApplicationStatus from "@/components/ApplicationStatusDropdown";
import FormattedDate from "@/components/FormattedDate";
import DocumentViewerModal from "@/components/Document/ViewerModal";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: {
    FormattedDate,
    FormGroupFile,
    FormGroupMultiselectPaginate,
    Fab,
    ApplicationStatus,
    DocumentViewerModal
  },
  props: {
    candidateId: { type: [String, Number] }
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchCandidate(to.params.candidateId).finally(this.stopFetching);
    next();
  },
  data() {
    return {
      applicationSelectedOffer: {},
      applicationPayload: {
        offer: null,
        resume: null,
        cover_letter: null,
        candidate_id: this.candidateId
      },
      applicationFields: [
        { key: "offer_title", label: this.$gettext("Offer") },
        { key: "company_name", label: this.$gettext("Company") },
        { key: "status", label: this.$gettext("Status") },
        { key: "created_at", label: this.$gettext("Created at") },
        { key: "updated_at", label: this.$gettext("Updated at") }
      ],
      documentFields: [
        { key: "type", label: this.$gettext("Type") },
        { key: "created_at", label: this.$gettext("Created at") },
        { key: "updated_at", label: this.$gettext("Updated at") },
        {
          key: "actions",
          label: this.$gettext("Actions"),
          tdClass: ["text-right"],
          thClass: "text-right"
        }
      ],
      documentViewed: {
        url: "",
        title: "",
        mimeType: ""
      }
    };
  },
  created() {
    this.fetchCandidate(this.candidateId).finally(this.stopFetching);
  },
  computed: {
    ...mapGetters("candidates", { candidate: "item" }),
    candidateCity() {
      return this.candidate?.location?.address || "-";
    }
  },
  methods: {
    ...mapActions("candidates", {
      fetchCandidate: "fetchOne",
      apply: "apply",
      updateApplicationStatus: "updateApplicationStatus"
    }),
    ...mapActions("offers", { fetchOfferOptions: "fetchOptions" }),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("form", ["submit"]),
    handleCreateApplication(event) {
      event.preventDefault();
      const payload = {
        ...this.applicationPayload,
        offer: this.applicationSelectedOffer.id ?? null
      };
      const form = Object.keys(payload).reduce((form, index) => {
        if (payload[index] !== null) {
          form.append(index, payload[index]);
        }
        return form;
      }, new FormData());
      this.submit(() => this.apply(form)).then(() => {
        this.fetchCandidate(this.candidateId).then(() => {
          this.$bvModal.hide("create-application-modal");
        });
      });
    },
    handleStatusChange({ id, status }) {
      this.updateApplicationStatus({ id, status });
    },
    translateDocumentType(type) {
      switch (type) {
        case "resume":
          return this.$gettext("Resume");
        case "cover_letter":
          return this.$gettext("Cover Letter");
      }
    },
    handleViewDocument(document) {
      this.documentViewed = {
        url: document.cdn,
        title: this.translateDocumentType(document.type),
        mimeType: document.mime_type
      };
      this.$bvModal.show("document-viewer-modal");
    }
  }
};
</script>
