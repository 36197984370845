<template>
  <b-dropdown :text="translatedCurrentStatus" variant="info">
    <b-dropdown-item
      v-for="(status, index) in statusList"
      :key="index"
      @click="handleStatusChange(index)"
      >{{ status }}</b-dropdown-item
    >
  </b-dropdown>
</template>
<script>
import { applicationStatus } from "@/enums/applicationStatus";

export default {
  props: {
    currentStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    statusList() {
      return applicationStatus;
    },
    translatedCurrentStatus() {
      return this.statusList[this.currentStatus];
    }
  },
  methods: {
    handleStatusChange(status) {
      this.$emit("statusChange", status);
    }
  }
};
</script>
